import { FC, useContext } from "react";
import { useHistory } from "react-router-dom";
import { Raidtext } from "../../Interfaces";
import DataContext from "../../store/data-context";
import CreateRaid from "./CreateRaid";

const CreateMultiRaidPage : FC = () => {
  const history = useHistory<any>();
  const raidtextid = history.location.state?.editIndex > -1 ? history.location.state.editIndex : -1;
  const dataContext = useContext(DataContext);

  const onSuccess = (raidtext: Raidtext): void => {
    dataContext.saveRaidText(raidtext, raidtextid);
    history.goBack();
  }

  return (
    <>
      <CreateRaid onsuccess={onSuccess} raidtextid={raidtextid} single={false} />
    </>
  )
}

export default CreateMultiRaidPage;