import { FC, ReactNode, useContext } from 'react';
import { useHistory } from 'react-router';
import DataContext from '../../store/data-context';

interface Props {
  location: string,
  icon?: ReactNode | string,
  text: string
}

const HomeMenuItem: FC<Props> = (props) => {
  const history = useHistory();
  const dataContext = useContext(DataContext);
  const onClick = () => {
    if (props.location === '/createmultiraid' || props.location === '/createsingleraid') {
      dataContext.clearRaidtexts();
    }
    history.push(props.location, { from: 'home' })
  }
  return (
    <div onClick={onClick} className="btn grey darken-3 white-text">
      <div className="menu-icon left">{typeof (props.icon) === 'string' ? <img src={props.icon} alt="" /> : props.icon}</div>
      <div className="left-align">{props.text}</div>
    </div>
  );
}

export default HomeMenuItem;