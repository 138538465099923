import { FC, useContext, useEffect, useState } from "react";
import { Button, Preloader, Switch } from "react-materialize";
import Api from '../../../api/api';
import Header from "../../Header";
import DataContext from "../../../store/data-context";
import { StoreContextProps } from "../../../Interfaces";

interface Silphboss {
  name: string;
  level: number;
}

interface Raidboss {
  name: string;
  level: number;
  _id: string;
}

const AdminCheckRaidbossesPage: FC = () => {
  const [updateBosses, setUpdateBosses] = useState<{ add: Raidboss[]; remove: Raidboss[]; notfound: Silphboss[] }>({ add: [], remove: [], notfound: [] });
  const [bossSwitches, setBossSwitches] = useState<{ [name: string]: boolean; }>({});
  const [loading, setLoading] = useState<boolean>(true);
  const [loadingText, setLoadingText] = useState<string>('Bezig met laden...');
  const [buttonDisabled, setButtonDisabled] = useState<boolean>(false);
  const dataContext = useContext<StoreContextProps>(DataContext);

  useEffect(() => {
    let mounted = true;
    (async () => {
      const toRemove: Raidboss[] = [];
      const toAdd: Raidboss[] = [];
      const notFound: Silphboss[] = [];
      const switches: { [name: string]: boolean; } = {};

      const data = await Api.getSilphBosses();
      if (('html' in data)) {
        const currentBosses = dataContext.getRaidbosses();
        const silphBosses: Silphboss[] = [];
        const aliases = data.alias;
        const html = new DOMParser().parseFromString(data.html, 'text/html');

        html.querySelectorAll<HTMLDivElement>('.raid-boss-tier-wrap').forEach(tier => {
          const level = tier.querySelector('h4')?.innerText.trim().replace(/Tier /g, '').replace(/Mega Raids/g, '6') || -1;
          while (tier.nextElementSibling?.className === 'raid-boss-tier') {
            tier = tier.nextElementSibling as HTMLDivElement;
            silphBosses.push({ name: tier.querySelector<HTMLDivElement>('.boss-name')!.innerText, level: +level });
          }
        });

        currentBosses.forEach(boss => {
          let remove = true;
          const alias = aliases.find(alias => alias.name === boss.name);
          if (alias) {
            for (let name of alias.alias) {
              if (silphBosses.some(silphboss => silphboss.name === name)) {
                remove = false;
              }
            }
          }
          if (silphBosses.some(silphboss => silphboss.name === boss.name)) {
            remove = false;
          }
          if (remove) {
            toRemove.push({ name: boss.name, level: boss.raidlevel, _id: boss._id });
            switches[boss.name] = true;
          }
        });

        silphBosses.forEach(silphboss => {
          const alias = aliases.find(alias => alias.alias.includes(silphboss.name));
          if (alias) {
            silphboss.name = alias.name;
          }
          const boss = dataContext.getPokemonByName(silphboss.name);
          if (boss) {
            if (boss.raidlevel !== silphboss.level) {
              toAdd.push({ name: boss.name, level: silphboss.level, _id: boss._id });
              switches[silphboss.name] = true;
            }
          } else {
            notFound.push(silphboss);
          }
        });

        toAdd.sort((a, b) => b.level - a.level || a.name.localeCompare(b.name));
        notFound.sort((a, b) => b.level - a.level || a.name.localeCompare(b.name));
        toRemove.sort((a, b) => b.level - a.level || a.name.localeCompare(b.name));
      }

      if (mounted) {
        setUpdateBosses({ add: toAdd, remove: toRemove, notfound: notFound });
        setBossSwitches(switches);
        setLoading(false);
        setButtonDisabled(Object.keys(switches).length === 0);
      }
    })();
    return function cleanup() {
      mounted = false;
    }
  }, [dataContext]);

  useEffect(() => {
    setButtonDisabled(Object.values(bossSwitches).filter(val => val).length === 0);
  }, [bossSwitches]);

  const toggleEnabled = (boss: string) => {
    setBossSwitches(prev => {
      return { ...prev, [boss]: !prev[boss] }
    });
  }

  const applyChanges = async () => {
    setLoadingText('Bezig met updaten...');
    setLoading(true);
    const query: { add: string[], remove: string[]; } = { add: [], remove: [] };
    updateBosses.remove.forEach(boss => {
      if (bossSwitches[boss.name]) {
        query.remove.push(boss._id);
      }
    });
    updateBosses.add.forEach(boss => {
      if (bossSwitches[boss.name]) {
        query.add.push(`${boss._id}||${boss.level}`);
      }
    });

    const result = await Api.updateMultiple(query);
    if ('pokemon' in result) {
      dataContext.storePokemon(result.pokemon);
    } else {
      setLoading(false);
    }
  }

  return (
    <>
      <Header backbutton={true} page="admincheckraidbosses" title='Controleer Raidbosses' />
      <div className="content-container">
        {loading ?
          <div className="loader">
            <Preloader
              active
              color="blue"
              flashing={false}
              size="big"
            />
            <p>{loadingText}</p>
          </div>
          : <>
            <div style={{ fontWeight: 'bold' }}>Bosses die toegevoegd worden:</div>
            {updateBosses.add.map(boss => <RaidBoss switchState={bossSwitches[boss.name]} key={boss.name} boss={boss} toggleEnabled={toggleEnabled} />)}
            {updateBosses.add.length === 0 && <div className="margin-top-10">Geen</div>}
            <div style={{ fontWeight: 'bold', marginTop: '20px' }}>Bosses die verwijderd worden:</div>
            {updateBosses.remove.map(boss => <RaidBoss switchState={bossSwitches[boss.name]} key={boss.name} boss={boss} toggleEnabled={toggleEnabled} />)}
            {updateBosses.remove.length === 0 && <div className="margin-top-10">Geen</div>}
            <div style={{ fontWeight: 'bold', marginTop: '20px' }}>Bosses die niet gevonden konden worden:</div>
            {updateBosses.notfound.map(boss => <RaidBoss switchState={null} key={boss.name} boss={boss} />)}
            {updateBosses.notfound.length === 0 && <div className="margin-top-10">Geen</div>}
            <Button className="margin-top-40" disabled={buttonDisabled} onClick={applyChanges}>Bijwerken</Button>
          </>
        }
      </div>
    </>
  )
}

interface Props {
  boss: Silphboss | Raidboss;
  switchState: boolean | null;
  toggleEnabled?: (name: string) => void;
}

const RaidBoss: FC<Props> = ({ boss, switchState, toggleEnabled }) => {
  return (
    <div style={{ justifyContent: 'space-between' }} className="horizontal-box margin-top-10">
      <div>Level {boss.level}: {boss.name}</div>
      {switchState !== null &&
        <Switch
          onLabel=''
          offLabel=''
          onChange={toggleEnabled ? () => toggleEnabled(boss.name) : () => { }}
          checked={switchState}
        />
      }
    </div>
  )
}

export default AdminCheckRaidbossesPage;