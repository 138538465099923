interface Pokemon {
  [key: string]: any;
  _id: string,
  atk: number,
  def: number,
  doubleweakness: string,
  maxcp20: number,
  maxcp25: number,
  mincp20: number,
  mincp25: number,
  name: string,
  number: string,
  raidlevel: number,
  sta: number,
  type: string,
  weakness: string,
  weatherboosted: string
}

const cpMultipliers: { [key: number]: number } = {
  1: 0.094,
  1.5: 0.1351374318,
  2: 0.16639787,
  2.5: 0.192650919,
  3: 0.21573247,
  3.5: 0.2365726613,
  4: 0.25572005,
  4.5: 0.2735303812,
  5: 0.29024988,
  5.5: 0.3060573775,
  6: 0.3210876,
  6.5: 0.3354450362,
  7: 0.34921268,
  7.5: 0.3624577511,
  8: 0.3752356,
  8.5: 0.387592416,
  9: 0.39956728,
  9.5: 0.4111935514,
  10: 0.4225,
  10.5: 0.4329264091,
  11: 0.44310755,
  11.5: 0.4530599591,
  12: 0.4627984,
  12.5: 0.472336093,
  13: 0.48168495,
  13.5: 0.4908558003,
  14: 0.49985844,
  14.5: 0.508701765,
  15: 0.51739395,
  15.5: 0.5259425113,
  16: 0.5343543,
  16.5: 0.5426357375,
  17: 0.5507927,
  17.5: 0.5588305862,
  18: 0.5667545,
  18.5: 0.5745691333,
  19: 0.5822789,
  19.5: 0.5898879072,
  20: 0.5974,
  20.5: 0.6048236651,
  21: 0.6121573,
  21.5: 0.6194041216,
  22: 0.6265671,
  22.5: 0.6336491432,
  23: 0.64065295,
  23.5: 0.6475809666,
  24: 0.65443563,
  24.5: 0.6612192524,
  25: 0.667934,
  25.5: 0.6745818959,
  26: 0.6811649,
  26.5: 0.6876849038,
  27: 0.69414365,
  27.5: 0.70054287,
  28: 0.7068842,
  28.5: 0.7131691091,
  29: 0.7193991,
  29.5: 0.7255756136,
  30: 0.7317,
  30.5: 0.7347410093,
  31: 0.7377695,
  31.5: 0.7407855938,
  32: 0.74378943,
  32.5: 0.7467812109,
  33: 0.74976104,
  33.5: 0.7527290867,
  34: 0.7556855,
  34.5: 0.7586303683,
  35: 0.76156384,
  35.5: 0.7644860647,
  36: 0.76739717,
  36.5: 0.7702972656,
  37: 0.7731865,
  37.5: 0.7760649616,
  38: 0.77893275,
  38.5: 0.7817900548,
  39: 0.784637,
  39.5: 0.7874736075,
  40: 0.7903,
};

const types: { [key: string]: number } = {
  'Bug': 0,
  'Dark': 1,
  'Dragon': 2,
  'Electric': 3,
  'Fairy': 4,
  'Fighting': 5,
  'Fire': 6,
  'Flying': 7,
  'Ghost': 8,
  'Grass': 9,
  'Ground': 10,
  'Ice': 11,
  'Normal': 12,
  'Poison': 13,
  'Psychic': 14,
  'Rock': 15,
  'Steel': 16,
  'Water': 17
};

const typeEffective: { [key: string]: number } = {
  'Bug-Fairy': 0.5,
  'Bug-Fighting': 0.5,
  'Bug-Fire': 0.5,
  'Bug-Flying': 0.5,
  'Bug-Ghost': 0.5,
  'Bug-Poison': 0.5,
  'Bug-Steel': 0.5,
  'Bug-Dark': 2,
  'Bug-Grass': 2,
  'Bug-Psychic': 2,
  'Dark-Dark': 0.5,
  'Dark-Fairy': 0.5,
  'Dark-Fighting': 0.5,
  'Dark-Ghost': 2,
  'Dark-Psychic': 2,
  'Dragon-Fairy': 0.5,
  'Dragon-Steel': 0.5,
  'Dragon-Dragon': 2,
  'Electric-Dragon': 0.5,
  'Electric-Electric': 0.5,
  'Electric-Grass': 0.5,
  'Electric-Ground': 0.5,
  'Electric-Flying': 2,
  'Electric-Water': 2,
  'Fairy-Fire': 0.5,
  'Fairy-Poison': 0.5,
  'Fairy-Steel': 0.5,
  'Fairy-Dark': 2,
  'Fairy-Dragon': 2,
  'Fairy-Fighting': 2,
  'Fighting-Bug': 0.5,
  'Fighting-Fairy': 0.5,
  'Fighting-Flying': 0.5,
  'Fighting-Ghost': 0.5,
  'Fighting-Poison': 0.5,
  'Fighting-Psychic': 0.5,
  'Fighting-Dark': 2,
  'Fighting-Ice': 2,
  'Fighting-Normal': 2,
  'Fighting-Rock': 2,
  'Fighting-Steel': 2,
  'Fire-Dragon': 0.5,
  'Fire-Fire': 0.5,
  'Fire-Rock': 0.5,
  'Fire-Water': 0.5,
  'Fire-Bug': 2,
  'Fire-Grass': 2,
  'Fire-Ice': 2,
  'Fire-Steel': 2,
  'Flying-Electric': 0.5,
  'Flying-Rock': 0.5,
  'Flying-Steel': 0.5,
  'Flying-Bug': 2,
  'Flying-Fighting': 2,
  'Flying-Grass': 2,
  'Ghost-Dark': 0.5,
  'Ghost-Normal': 0.5,
  'Ghost-Ghost': 2,
  'Ghost-Psychic': 2,
  'Grass-Bug': 0.5,
  'Grass-Dragon': 0.5,
  'Grass-Fire': 0.5,
  'Grass-Fighting': 0.5,
  'Grass-Grass': 0.5,
  'Grass-Poison': 0.5,
  'Grass-Steel': 0.5,
  'Grass-Ground': 2,
  'Grass-Rock': 2,
  'Grass-Water': 2,
  'Ground-Bug': 0.5,
  'Ground-Flying': 0.5,
  'Ground-Grass': 0.5,
  'Ground-Electric': 2,
  'Ground-Fire': 2,
  'Ground-Poison': 2,
  'Ground-Rock': 2,
  'Ground-Steel': 2,
  'Ice-Fire': 0.5,
  'Ice-Ice': 0.5,
  'Ice-Steel': 0.5,
  'Ice-Water': 0.5,
  'Ice-Dragon': 2,
  'Ice-Flying': 2,
  'Ice-Grass': 2,
  'Ice-Ground': 2,
  'Normal-Ghost': 0.5,
  'Normal-Rock': 0.5,
  'Normal-Steel': 0.5,
  'Poison-Ghost': 0.5,
  'Poison-Ground': 0.5,
  'Poison-Poison': 0.5,
  'Poison-Rock': 0.5,
  'Poison-Steel': 0.5,
  'Poison-Fairy': 2,
  'Poison-Grass': 2,
  'Psychic-Dark': 0.5,
  'Psychic-Psychic': 0.5,
  'Psychic-Steel': 0.5,
  'Psychic-Fighting': 2,
  'Psychic-Poison': 2,
  'Rock-Fighting': 0.5,
  'Rock-Ground': 0.5,
  'Rock-Steel': 0.5,
  'Rock-Bug': 2,
  'Rock-Fire': 2,
  'Rock-Flying': 2,
  'Rock-Ice': 2,
  'Steel-Electric': 0.5,
  'Steel-Fire': 0.5,
  'Steel-Steel': 0.5,
  'Steel-Water': 0.5,
  'Steel-Fairy': 2,
  'Steel-Ice': 2,
  'Steel-Rock': 2,
  'Water-Dragon': 0.5,
  'Water-Grass': 0.5,
  'Water-Water': 0.5,
  'Water-Fire': 2,
  'Water-Ground': 2,
  'Water-Rock': 2
}

export const getCpAtLevel = (pokemon: Pokemon, level: number, iv: number) => {
  return Math.max(10, Math.floor(((pokemon.atk + iv) * (Math.pow(pokemon.def + iv, .5)) * (Math.pow(pokemon.sta + iv, .5)) * Math.pow(cpMultipliers[level], 2)) / 10));
}

export const getWeakness = (pokemon: Pokemon, doubleWeakness = false) => {
  const weakness = [];
  const pokemonTypes = pokemon.type.replace(/ /g, '').split(',');
  for (const type in types) {
    let effective = 1;
    if (pokemonTypes.length === 1) {
      if (typeEffective.hasOwnProperty(`${type}-${pokemonTypes[0]}`)) {
        effective = typeEffective[`${type}-${pokemonTypes[0]}`];
      }
    } else {
      let effective1 = 1;
      let effective2 = 1;
      if (typeEffective.hasOwnProperty(`${type}-${pokemonTypes[0]}`)) {
        effective1 = typeEffective[`${type}-${pokemonTypes[0]}`];
      }
      if (typeEffective.hasOwnProperty(`${type}-${pokemonTypes[1]}`)) {
        effective2 = typeEffective[`${type}-${pokemonTypes[1]}`];
      }
      effective = effective1 * effective2;
    }

    if ((!doubleWeakness && effective === 2) || (doubleWeakness && effective === 4)) {
      weakness.push(type);
    }
  };
  weakness.sort((a, b) => a.toUpperCase().localeCompare(b.toUpperCase()));
  return [...new Set(weakness)].join(', ');
}

export const getWeatherBoost = (types: string) => {
  const boosted = [];
  if (types.includes('Bug')) boosted.push('Rain');
  if (types.includes('Dark')) boosted.push('Fog');
  if (types.includes('Dragon')) boosted.push('Windy');
  if (types.includes('Electric')) boosted.push('Rain');
  if (types.includes('Fairy')) boosted.push('Cloudy');
  if (types.includes('Fighting')) boosted.push('Cloudy');
  if (types.includes('Fire')) boosted.push('Sunny/Clear');
  if (types.includes('Flying')) boosted.push('Windy');
  if (types.includes('Ghost')) boosted.push('Fog');
  if (types.includes('Grass')) boosted.push('Sunny/Clear');
  if (types.includes('Ground')) boosted.push('Sunny/Clear');
  if (types.includes('Ice')) boosted.push('Snow');
  if (types.includes('Normal')) boosted.push('Partly Cloudy');
  if (types.includes('Poison')) boosted.push('Cloudy');
  if (types.includes('Psychic')) boosted.push('Windy');
  if (types.includes('Rock')) boosted.push('Partly Cloudy');
  if (types.includes('Steel')) boosted.push('Snow');
  if (types.includes('Water')) boosted.push('Rain');
  return [...new Set(boosted)].join(' en ');
}

