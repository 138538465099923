import React, { FC } from 'react';
import { Button } from 'react-materialize';
import { useHistory } from 'react-router';
import Header from './Header';

interface Props {
  error?: boolean;
}

const ErrorPage: FC<Props> = ({ error }) => {
  const history = useHistory();
  return (
    error ?
      <React.Fragment>
        <Header page="error" title="Fout opgetreden" />
        <div className="content-container container">
          <p>Er kon geen verbinding gemaakt worden met de database.</p>
          <p>Probeer het later nog een keer.</p>
        </div>
      </React.Fragment>
      :
      <React.Fragment>
        <Header page="error" title="Niet gevonden" />
        <div className="content-container container">
          <p>De opgevraagde pagina kon niet gevonden worden.</p>
          <p>Controleer het adres en probeer het opnieuw. Of druk op onderstaande button om naar de homepage te gaan.</p>
          <Button onClick={() => history.push('/')}>Naar de homepage</Button>
        </div>
      </React.Fragment>
  );
}

export default ErrorPage;