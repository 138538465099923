import { createContext, FC, useState } from 'react';
import { getCpAtLevel, getWeatherBoost, getWeakness } from '../helpers/pokemon';
import { Poi, Pokemon, Raidtext, Settings, StoreContextProps } from '../Interfaces';

const defaultSettings = {
  version: '1.1.6',
  initstatus: 'initializing',
  admin: 0,
  raidtexttemplate: '',
  raidtimer: 0,
  iospopupclosed: false,
  updated: 0
}

const defaultPokemon = {
  _id: '',
  atk: 0,
  def: 0,
  doubleweakness: '',
  maxcp20: 0,
  maxcp25: 0,
  mincp20: 0,
  mincp25: 0,
  name: '',
  number: '',
  raidlevel: 0,
  sta: 0,
  type: '',
  weakness: '',
  weatherboosted: ''
}

const DataContext = createContext<StoreContextProps>({
  pokemon: [],
  pois: [],
  raidtexts: [],
  settings: defaultSettings,
  saveRaidText: (raidtext: Raidtext, index: number) => { },
  clearRaidtexts: () => { },
  getRaidtext: (index: number) => { return { raidboss: '', gym: { name: '', type: 0, location: '', coords: '', _id: '' }, eiopen: '', start: '', startafterprevious: false, addmyself: false } },
  removeRaidtext: (index: number) => { },
  getLastRaidGym: (index: number) => { return {} },
  storePokemon: (_pokemon: Pokemon[]) => { },
  storePois: (_pois: Poi[]) => {},
  storeSetting: (_setting: string, _value: any) => { },
  storeSettings: (_settings: {}) => { },
  getRaidbosses: () => Array<Pokemon>(),
  getPokemonByName: (name: String) => defaultPokemon
});

export const DataContextProvider: FC = (props) => {
  const [pokemon, setPokemon] = useState<Pokemon[]>([]);
  const [pois, setPois] = useState<Poi[]>([]);
  const [raidtexts, setRaidtexts] = useState<Raidtext[]>([]);
  const [settings, setSettings] = useState<Settings>({ ...defaultSettings });
  //const [adminManageRaidbosses, setAdminManageRaidbosses] = useState<Pokemon[]>([]);

  function _storePokemon(_pokemon: Pokemon[]): void {
    const pokemonToAdd = _pokemon.map(pokemon => {
      if (pokemon.raidlevel > 0) {
        pokemon.mincp20 = getCpAtLevel(pokemon, 20, 10);
        pokemon.maxcp20 = getCpAtLevel(pokemon, 20, 15);
        pokemon.mincp25 = getCpAtLevel(pokemon, 25, 10);
        pokemon.maxcp25 = getCpAtLevel(pokemon, 25, 15);
        pokemon.weatherboosted = getWeatherBoost(pokemon.type);
        pokemon.weakness = getWeakness(pokemon);
        pokemon.doubleweakness = getWeakness(pokemon, true);
      }
      return pokemon;
    });
    setPokemon(pokemonToAdd.length > 0 ? pokemonToAdd.sort((a, b) => a.name.localeCompare(b.name)) : []);
  }

  function _storePois(_pois: Poi[]): void {
    setPois(_pois);
  }

  function _storeSettings(_settings: {}): void {
    setSettings(prev => { return { ...prev, ..._settings } });
  }

  function _storeSetting(_setting: string, _value: any): void {
    setSettings(prev => {
      prev[_setting] = _value;
      return prev;
    });
  }

  function _saveRaidText(raidtext: Raidtext, index: number): void {
    if (index === -1) {
      setRaidtexts(prev => [...prev, raidtext]);
    } else {
      setRaidtexts(prev => {
        const texts = [...prev];
        texts[index] = raidtext;
        return texts;
      });
    }
  }

  function _getRaidtext(index: number): Raidtext {
    return raidtexts[index] || {
      raidboss: 'Mega Raid',
      gym: pois[0],
      eiopen: '',
      start: '',
      startafterprevious: false,
      addmyself: localStorage.getItem('autoadd') === 'ja',
    };
  }

  function _removeRaidtext(index: number): void {
    setRaidtexts(prev => {
      const newArray = [...prev];
      newArray.splice(index, 1);
      if (newArray.length > 0) {
        newArray[0].startafterprevious = false;
      }
      return newArray;
    });
  }

  function _getLastRaidGym(index: number): Poi | {} {
    if (index === 0) {
      return {}
    }
    if (index === -1) {
      return raidtexts.length === 0 ? {} : raidtexts[raidtexts.length - 1].gym;
    } else {
      return raidtexts[index - 1].gym;
    }
  }

  const context = {
    pokemon,
    pois,
    storePois: _storePois,
    raidtexts,
    settings,
    storeSettings: _storeSettings,
    saveRaidText: _saveRaidText,
    clearRaidtexts: () => setRaidtexts([]),
    getRaidtext: _getRaidtext,
    getLastRaidGym: _getLastRaidGym,
    storePokemon: _storePokemon,
    storeSetting: _storeSetting,
    getPokemonByName: (name: String) => pokemon.find(p => p.name === name),
    getRaidbosses: () => pokemon.filter(pokemon => pokemon.raidlevel > -1),
    removeRaidtext: _removeRaidtext,
  };

  return <DataContext.Provider value={context}>
    {props.children}
  </DataContext.Provider>
}

export default DataContext;