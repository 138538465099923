import { useEffect, useContext, useRef, FC } from 'react';
import chatIcon from '../../images/chat.png';
import multiChatIcon from '../../images/chatmulti.png';
import listIcon from '../../images/list.png';
import locationIcon from '../../images/location.png';
import rulesIcon from '../../images/rules.png';
import iosShareIcon from '../../images/iosshare.png';
import Header from '../Header';
import HomeMenuItem from './HomeMenuItem';
import M from "materialize-css";
import DataContext from '../../store/data-context';

const HomePage: FC = () => {
  const dataContext = useContext(DataContext);
  const modalButtonRef = useRef<HTMLButtonElement>(null);

  useEffect(() => {
    let elems: NodeListOf<Element> = document.querySelectorAll('.modal');
    M.Modal.init(elems, {
      dismissible: false
    });
  }, []);

  useEffect(() => {
    const navigator: any = window.navigator;
    if (("standalone" in navigator) && !navigator.standalone) {
      if (!dataContext.settings.iospopupclosed) {
        modalButtonRef.current?.click();
      }
    }
  }, [dataContext]);

  const closeBannerButton = () => {
    dataContext.storeSetting('iospopupclosed', true);
  }

  return (
    <>
      <Header page="home" title="Raids 0251" />
      <div className="content-container">
        <div className="menu-sub">Raids</div>
        <HomeMenuItem icon={chatIcon} text="Nieuwe Raid Melden" location="/createsingleraid" />
        <HomeMenuItem icon={multiChatIcon} text="Nieuwe Multi-raid Melden" location="/createmultiraid" />
        <HomeMenuItem icon={listIcon} text="Huidige Raidbosses" location="/currentraidbosses" />
        <div className="div margin-bottom-20"></div>
        <div className="menu-sub">Gyms</div>
        <HomeMenuItem icon={locationIcon} text="Gym Locatie Zoeken of Delen" location="/findgym" />
        <div className="div margin-bottom-20"></div>
        <div className="menu-sub">WhatsApp</div>
        <HomeMenuItem icon={rulesIcon} text="Spelregels" location="/whatsapprules" />
      </div>
      <button data-target="message-modal" className="btn modal-trigger hide" id="modal-button">Modal</button>
      <div id="message-modal" className="modal">
        <div className="modal-content black-text">
          <h5>Als app installeren</h5>
          <div className="divider"></div>
          <p>Indien je deze website bekijkt in <b>Safari</b> kun je deze ook installeren als app via de volgende 3 stappen:</p>
          <div className="margin-top-40" style={{ display: 'flex', alignItems: 'center'}}><b>1:</b>&nbsp;Druk op <img alt='share' style={{ marginLeft: '5px'}} src={iosShareIcon} /></div>
          <div className="margin-top-10"><b>2:</b>&nbsp;Druk op <b>'Zet op beginscherm'</b></div>
          <div className="margin-top-10"><b>3:</b>&nbsp;Druk op <b>'Voeg toe'</b></div>
          <div className="margin-top-40">Nu kun je Raids 0251 voortaan als app openen vanaf je beginscherm.</div>
        </div>
        <div className="container center-align margin-bottom-20">
          <button ref={modalButtonRef} onClick={closeBannerButton} className="modal-action modal-close btn">Sluiten</button>
        </div>
      </div>
    </>
  )
}

export default HomePage;