import { FC, useContext, useEffect, useRef, useState } from "react";
import DataContext from "../../store/data-context";
import Header from "../Header";
import M from "materialize-css";
import { Raidtext } from "../../Interfaces";
import Edit from '@material-ui/icons/Edit';
import Delete from '@material-ui/icons/Delete';
import { useHistory } from "react-router";

const CreateMultiRaidListPage: FC = () => {
  const history = useHistory();
  const [textbuttons, setTextbuttons] = useState<string[]>([]);
  const [modalHeader, setModalHeader] = useState<string>('');
  const [modalText, setModalText] = useState<string>('');
  const [modalType, setModalType] = useState<string>('notification');
  const [modalConfirm, setModalConfirm] = useState<() => void>(() => { });
  const modalButtonRef = useRef<HTMLButtonElement>(null);
  const dataContext = useContext(DataContext);

  useEffect(() => {
    let elems = document.querySelectorAll('.modal');
    M.Modal.init(elems, {
      dismissible: false
    });
  }, []);

  useEffect(() => {
    setTextbuttons(new Array(dataContext.raidtexts.length).fill('closed'));
  }, [dataContext]);

  const openModal = () => {
    modalButtonRef.current?.click();
  }

  const removeAllTexts = () => {
    setModalType('confirm');
    setModalHeader('Bevestig');
    setModalText('Weet je zeker dat je alle toegevoegde raids wilt verwijderen?');
    setModalConfirm(() => () => { dataContext.clearRaidtexts() });
    openModal();
  }

  const removeText = (index: number) => {
    setModalType('confirm');
    setModalHeader('Bevestig');
    setModalText('Weet je zeker dat je deze raid wilt verwijderen?');
    setModalConfirm(() => () => { dataContext.removeRaidtext(index) });
    openModal();
  }

  const toggleButtons = (index: number) => {
    setTextbuttons(prev => {
      const buttons = new Array(dataContext.raidtexts.length).fill('closed');
      buttons[index] = prev[index] === 'closed' ? 'opened' : 'closed';
      return buttons;
    });
  }

  const editText = (index: number) => {
    history.push('/createraid', { editIndex: index });
  }

  const shareText = () => {
    let shareText = '';
    let regex = /\[\?[^\]]*\]/g;

    if (dataContext.raidtexts.length === 0) {
      setModalType('notification');
      setModalHeader('Fout opgetreden');
      setModalText('Er zijn geen raids toegevoegd om te delen.');
      openModal();
      return;
    }

    if (dataContext.raidtexts.length > 1) {
      shareText += '*Multi-raid*\n\n';
    }

    dataContext.raidtexts.forEach((raid, i) => {
      if (i > 0) {
        shareText += '\n';
      }
      let raidtext = dataContext.settings.raidtexttemplate;
      let exraidmatch = raidtext.match(regex);
      let exraid = "";
      const raidstart = raid.startafterprevious ? `Na ${dataContext.raidtexts[i - 1].gym.name}` : raid.start;
      let exraidRegex = "";
      if (exraidmatch != null) {
        exraidRegex = exraidmatch[0];
        exraid = exraidmatch[0].replace('[?', '').replace(']', '');
      }

      raidtext = raidtext.replace('[raidbossname]', raid.raidboss);
      raidtext = raidtext.replace('[gymname]', raid.gym.name);
      raidtext = raidtext.replace('[location]', raid.gym.location);
      raidtext = raidtext.replace('[eggopen]', raid.eiopen);
      raidtext = raidtext.replace(' - [eggclose]', '');
      raidtext = raidtext.replace('[raidstart]', raidstart);
      raidtext = raidtext.replace(exraidRegex, raid.gym.type === 2 ? exraid : '');
      raidtext = raidtext.replace(/\|/g, '\n');
      if (raid.addmyself) {
        raidtext += `${localStorage.getItem('whatsappname')} ${localStorage.getItem('currentlevel')}\n\n`;
      }
      shareText += raidtext;
    });

    shareText = encodeURIComponent(shareText);
    window.open(`whatsapp://send?text=${shareText}`, '_blank');
  }

  return (
    <>
      <Header page="createmultiraid" backbutton={true} title="Multi-raid Tekst Maken" clearraidtexts={removeAllTexts} />
      <div className="content-container">
        <div className="flex-container margin-top-10 margin-bottom-10">
          <div>Alle raids die je hier toevoegt zullen worden samengevoegd in 1 multi-raid bericht.<br /><br />Druk op een raid om deze te bewerken of te verwijderen.</div>
          <div className="divider margin-top-10"></div>
        </div>
        <div className="content-container">
          {dataContext.raidtexts.map((raidtext, i) => <RaidText key={`raidtext${i}`} raidtexts={dataContext.raidtexts} index={i} buttonclass={textbuttons[i]} raidtext={raidtext} togglebuttons={toggleButtons} remove={removeText} edit={editText} />)}
        </div>
        <div className="footer-container margin-bottom-15">
          <div className="btn" onClick={() => editText(-1)}>RAID TOEVOEGEN</div>
          <div className="btn blue" onClick={shareText}>DELEN IN WHATSAPP</div>
        </div>
      </div>
      <button ref={modalButtonRef} data-target="message-modal" className="btn modal-trigger hide" id="modal-button">Modal</button>
      <div id="message-modal" className="modal bottom-sheet">
        <div className="modal-content black-text">
          <h5>{modalHeader}</h5>
          <div className="divider"></div>
          <p>{modalText}</p>
        </div>
        <div className="container center-align margin-bottom-20">
          {modalType === 'notification' && <button className="modal-action modal-close btn">Sluiten</button>}
          {modalType === 'confirm' &&
            <div className="horizontal-box">
              <button className="margin-right-10 modal-action modal-close btn">Annuleren</button>
              <button onClick={modalConfirm} className="margin-left-10 modal-action modal-close btn blue">Verwijderen</button>
            </div>
          }
        </div>
      </div>
    </>
  )
}

interface RaidTextProps {
  raidtexts: Raidtext[];
  raidtext: Raidtext;
  index: number;
  togglebuttons: (index: number) => void;
  edit: (index: number) => void;
  remove: (index: number) => void;
  buttonclass: string;
}

const RaidText: FC<RaidTextProps> = (props) => {
  const raidtext = props.raidtext;
  const startText = raidtext.startafterprevious
    ? `Na ${props.raidtexts[props.index - 1]?.gym.name}`
    : raidtext.start;

  return (
    <div className="horizontal-box">
      <div style={{ flex: 1 }} onClick={() => props.togglebuttons(props.index)} className="rounded-box">
        <div><b>{raidtext.raidboss}</b> {raidtext.gym.name}</div>
        <div><b>Locatie</b> {raidtext.gym.location}</div>
        <div><b>Ei Open</b> {raidtext.eiopen}</div>
        <div><b>Starttijd</b> {startText}</div>
        {raidtext.addmyself &&
          <div><br />{`${localStorage.getItem('whatsappname')} ${localStorage.getItem('currentlevel')}`}</div>
        }
      </div>
      <div className={`raidtext-buttons ${props.buttonclass}`}>
        <div onClick={() => props.edit(props.index)} className="btn"><Edit /></div>
        <div onClick={() => props.remove(props.index)} className="btn red"><Delete /></div>
      </div>
    </div>
  )
}

export default CreateMultiRaidListPage;