import { FC } from 'react';
import Header from '../Header';

const WhatsappRulesPage: FC = () => {
  return (
    <>
      <Header page="whatsapprules" backbutton={true} title="WhatsApp Spelregels" />
      <div className="content-container">
        <div>Om het voor iedereen enigszins leuk te houden hebben we voor de WhatsApp groepen een paar spelregels opgesteld.</div>
        <div className="rules-caption">Schelden en ruzies</div>
        <div>Schelden en ruzies houden we niet van. Heb je een meningsverschil met iemand, los die dan privÃ© op in plaats van de hele groep ermee lastig te vallen. Ook stoken tussen mensen om een ruzie uit te lokken zijn wij niet van gediend en kan een (tijdelijke) kick opleveren.</div>
        <div className="rules-caption">Spoofers</div>
        <div>Spoofers willen we niet in de groep. Bij spoofen of het vragen van informatie hierover volgt een permanente kick.</div>
        <div className="rules-caption">Actieve leden</div>
        <div>Wij willen graag alleen actieve leden in de groep, dus ben je uitgespeeld of een tijdje niet zo actief? Meld je dan af, zodat je plaats kunt maken voor een ander. Zodra je weer actief speelt ben je altijd weer van harte welkom.</div>
        <div className="rules-caption">De chat groep</div>
        <div>In de chatgroep kun je vragen stellen, raidtijden overleggen, screenshots delen, etc., zolang het beperkt blijft tot Pokemon Go. Probeer het voor iedereen gewoon gezellig te houden en beperk spam tot een minimum.</div>
        <div className="rules-caption">De raid groep</div>
        <div>In de raidgroep mag je alleen raid lijstjes delen. Dit om het overzichtelijk te houden. Wanneer je 'kletst' in de raid app, volgt een tijdelijke kick. Mocht je per ongeluk iets verkeerds in de raidgroep plaatsen, ga er dan niet nog een bericht onder plaatsen met een excuus maar verwijder je berichtje gewoon netjes voor iedereen.<br /><br />Wil je je aanmelden voor een raid?Kopieer het lijstje, en plak deze weer in de app nadat je jouw naam en level erbij hebt gezet.Wil je een nieuwe raid melden?Gebruik dan de optie 'Nieuwe Raid Melden' van deze app.</div>
      </div>
    </>
  );
}

export default WhatsappRulesPage;