import { FC, useContext } from 'react'
import { Redirect, Route, Switch, useHistory } from 'react-router'
import AdminHomepage from '../components/Admin/Home/HomePage'
import AdminAddPokemonPage from '../components/Admin/Raidbosses/AdminAddPokemonPage'
import AdminCheckRaidbossesPage from '../components/Admin/Raidbosses/AdminCheckRaidbossesPage'
import CurrentRaidbossesPage from '../components/CurrentRaidbosses/CurrentRaidbossesPage'
import ErrorPage from '../components/ErrorPage'
import FindGymPage from '../components/FindGym/FindGymPage'
import HomePage from '../components/Home/HomePage'
import CreateMultiRaidListPage from '../components/Raidtext/CreateMultiRaidListPage'
import CreateMultiRaidPage from '../components/Raidtext/CreateMultiRaidPage'
import CreateSingleRaidText from '../components/Raidtext/CreateSingleRaidPage'
import SettingsPage from '../components/Settings/SettingsPage'
import WhatsappRulesPage from '../components/WhatsAppRules/WhatsAppRules'
import { StoreContextProps } from '../Interfaces'
import DataContext from '../store/data-context'

const RouterSwitch: FC = () => {
  const history = useHistory();
  const dataContext = useContext<StoreContextProps>(DataContext);

  return (
    <Switch>
      <Redirect exact from="/index.html" to="/" />
      {!history.location.state && <Redirect exact from="/createraid" to="/" />}
      <Route exact path="/" component={HomePage} />
      <Route exact path="/currentraidbosses" component={CurrentRaidbossesPage} />
      <Route exact path='/findgym' component={FindGymPage} />
      <Route exact path='/settings' component={SettingsPage} />
      <Route exact path='/createraid' component={CreateMultiRaidPage} />
      <Route exact path='/createsingleraid' component={CreateSingleRaidText} />
      <Route exact path='/createmultiraid' component={CreateMultiRaidListPage} />
      <Route exact path='/whatsapprules' component={WhatsappRulesPage} />

      {/* {dataContext.settings.admin > 0 && <Route exact path='/admin/manageraidbosses' component={Admin} />} */}
      {dataContext.settings.admin > 0 && <Route exact path='/admin/checkraidbosses' component={AdminCheckRaidbossesPage} />}
      {dataContext.settings.admin > 0 && <Route exact path='/admin/addpokemon' component={AdminAddPokemonPage} />}
      {dataContext.settings.admin > 0 && <Route exact path='/admin' component={AdminHomepage} />}

      <Route component={ErrorPage} />
    </Switch>


  )
}

export default RouterSwitch
