import { FC, useContext, useRef, useState } from 'react';
import Header from '../Header';
import RaidbossItem from './RaidbossItem';
import Filter1 from '@material-ui/icons/Filter1';
import Filter2 from '@material-ui/icons/Filter2';
import Filter3 from '@material-ui/icons/Filter3';
import Filter4 from '@material-ui/icons/Filter4';
import Filter5 from '@material-ui/icons/Filter5';
import FilterMega from '@material-ui/icons/FilterMega';
import DataContext from '../../store/data-context';
import { Pokemon } from '../../Interfaces';

const CurrentRaidbossesPage: FC = () => {
  const dataContext = useContext(DataContext);
  const raidbosses: Pokemon[] = dataContext.getRaidbosses();
  const [level, setLevel] = useState<number>(1);
  const [visibleRaidbosses, setVisibleRaidbosses] = useState<Pokemon[]>(raidbosses.filter(raidboss => raidboss.raidlevel === 1));
  const ccRef = useRef<HTMLDivElement>(null);

  const changeLevel = (level: number) => {
    setLevel(level);
    setVisibleRaidbosses(raidbosses.filter(raidboss => raidboss.raidlevel === level));
    ccRef.current?.scrollTo(0, 0);
  }

  const noRaidBosses: {[key: number]: string } = {
    1: '1 ster',
    2: '2 sterren',
    3: '3 sterren',
    4: '4 sterren',
    5: '5 sterren',
    6: 'mega'
  }

  return (
    <>
      <Header page="currentraidbosses" title="Huidige Raidbosses" backbutton={true} />
      <div ref={ccRef} className="content-container">
        <div className="raidbosses">
          {visibleRaidbosses.length > 0 ?
            visibleRaidbosses.map(raidboss => <RaidbossItem key={raidboss._id} raidboss={raidboss} />) :
            <div>Er zijn op dit moment geen {noRaidBosses[level]} raidbosses bekend in onze database.</div>
          }
        </div>
      </div>
      <div className="flex-container max-width">
        <div className="raidbosses-footer">
          {raidbosses.filter(raidboss => raidboss.raidlevel === 1).length > 0 &&
            <div onClick={() => changeLevel(1)} className="col s3 center-align" style={{ color: level === 1 ? "#26a69a" : "#555" }}><Filter1 /></div>}
          {raidbosses.filter(raidboss => raidboss.raidlevel === 2).length > 0 &&
            <div onClick={() => changeLevel(2)} className="col s3 center-align" style={{ color: level === 2 ? "#26a69a" : "#555" }}><Filter2 /></div>}
          {raidbosses.filter(raidboss => raidboss.raidlevel === 3).length > 0 &&
            <div onClick={() => changeLevel(3)} className="col s3 center-align" style={{ color: level === 3 ? "#26a69a" : "#555" }}><Filter3 /></div>}
          {raidbosses.filter(raidboss => raidboss.raidlevel === 4).length > 0 &&
            <div onClick={() => changeLevel(4)} className="col s3 center-align" style={{ color: level === 4 ? "#26a69a" : "#555" }}><Filter4 /></div>}
          {raidbosses.filter(raidboss => raidboss.raidlevel === 5).length > 0 &&
            <div onClick={() => changeLevel(5)} className="col s3 center-align" style={{ color: level === 5 ? "#26a69a" : "#555" }}><Filter5 /></div>}
          {raidbosses.filter(raidboss => raidboss.raidlevel === 6).length > 0 &&
            <div onClick={() => changeLevel(6)} className="col s3 center-align" style={{ color: level === 6 ? "#26a69a" : "#555" }}><FilterMega /></div>}
        </div>
      </div>
    </>
  );
}

export default CurrentRaidbossesPage;