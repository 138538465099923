import { FC, useContext } from "react";
import { Raidtext } from "../../Interfaces";
import DataContext from "../../store/data-context";
import CreateRaid from "./CreateRaid";

const CreateSingleRaidText: FC = () => {
  const dataContext = useContext(DataContext);

  const onSuccess = (raidtext: Raidtext): void => {
    let regex = /\[\?[^\]]*\]/g;
    let template = dataContext.settings.raidtexttemplate;
    let exraidmatch = template.match(regex);
    let exraid = "";
    let exraidRegex = "";
    if (exraidmatch != null) {
      exraidRegex = exraidmatch[0];
      exraid = exraidmatch[0].replace('[?', '').replace(']', '');
    }

    template = template.replace('[raidbossname]', raidtext.raidboss);
    template = template.replace('[gymname]', raidtext.gym.name);
    template = template.replace('[location]', raidtext.gym.location);
    template = template.replace('[eggopen]', raidtext.eiopen);
    template = template.replace(' - [eggclose]', '');
    template = template.replace('[raidstart]', raidtext.start);
    template = template.replace(exraidRegex, raidtext.gym.type === 2 ? exraid : '');
    template = template.replace(/\|/g, '\n');
    if (raidtext.addmyself) {
      template += `${localStorage.getItem('whatsappname')} ${localStorage.getItem('currentlevel')}\n\n`;
    }
    template = encodeURIComponent(template);
    window.open(`whatsapp://send?text=${template}`, '_blank');
  }

  return (
    <>
      <CreateRaid onsuccess={onSuccess} raidtextid={-1} single={true} />
    </>
  )
}

export default CreateSingleRaidText;