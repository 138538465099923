import { FC, useContext } from 'react'
import { BrowserRouter as Router } from "react-router-dom";

import DataContext from '../store/data-context';
import LoadingPage from '../components/Loading/LoadingPage';
import RouterSwitch from './RouterSwitch';
import ErrorPage from '../components/ErrorPage';

const AppRouter: FC = () => {
  const dataContext = useContext(DataContext);
  const initStatus = dataContext.settings.initstatus || 'initializing';

  return (
    <div className="app">
      <Router>
        {initStatus === 'error'
          ? <ErrorPage error={true} />
          : initStatus === 'initializing'
            ? <LoadingPage />
            : <RouterSwitch />
        }
      </Router>
    </div>
  )
}

export default AppRouter
