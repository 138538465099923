import { Poi, Pokemon } from "../Interfaces";

interface Alias {
  alias: {
    id: string;
    name: string;
    alias: string[]
  }[]
  html: string;
};

class Api {
  apiUrl: string;
  authToken: string;

  constructor() {
    this.apiUrl = 'https://api.raids0251.nl/v3/app';
    //this.apiUrl = 'http://localhost:3000/v3/app';
    this.authToken = localStorage.getItem('auth-token') || '';
  }

  // ************************************************************
  // * Get request to the API                                   *
  // ************************************************************
  __getUrl = async (url: string) => {
    try {
      const res = await fetch(url, {
        headers: {
          Accept: 'application/json',
          'Content-Type': 'application/json',
          'requestby': 'raids0251',
          'auth-token': this.authToken
        },
      });
      const json = await res.json();
      return json;
    }
    catch (e) {
      return { error: 'Fout bij verbinden met de server' };
    }
  }

  // ************************************************************
  // * Post request to the API                                  *
  // ************************************************************
  __postUrl = async (url: string, params = {}) => {
    try {
      const res = await fetch(url, {
        method: 'POST',
        headers: {
          Accept: 'application/json',
          'Content-Type': 'application/json',
          'requestby': 'raids0251',
          'auth-token': this.authToken
        },
        body: JSON.stringify(params)
      });
      const json = await res.json();
      return json;
    }
    catch (e) {
      return { error: 'Fout bij verbinden met de server' };
    }
  }

  // ************************************************************
  // * Patch request to the API                                  *
  // ************************************************************
  __patchUrl = async (url: string, params = {}) => {
    try {
      const res = await fetch(url, {
        method: 'PATCH',
        headers: {
          Accept: 'application/json',
          'Content-Type': 'application/json',
          'requestby': 'raids0251',
          'auth-token': this.authToken
        },
        body: JSON.stringify(params)
      });
      const json = await res.json();
      return json;
    }
    catch (e) {
      return { error: 'Fout bij verbinden met de server' };
    }
  }

  // ************************************************************
  // * Patch request to the API                                  *
  // ************************************************************
  __removeUrl = async (url: string, params = {}) => {
    try {
      const res = await fetch(url, {
        method: 'DELETE',
        headers: {
          Accept: 'application/json',
          'Content-Type': 'application/json',
          'requestby': 'raids0251',
          'auth-token': this.authToken
        },
        body: JSON.stringify(params)
      });
      const json = await res.json();
      return json;
    }
    catch (e) {
      return { error: 'Fout bij verbinden met de server' };
    }
  }

  // ************************************************************
  // * Init                                                     *
  // ************************************************************
  init = async (): Promise<{ updated: number } | { error: string }> => {
    const res = await this.__postUrl(`${this.apiUrl}/initialize/update`, {});
    return res;
  }

  getDbData = async (): Promise<{ admin: number, lastupdate: number, pokemon: Pokemon[], pois: Poi[], settings: { admin: number, raidtexttemplate: string, raidtimer: number, updated: number } } | { error: string }> => {
    const res = await this.__postUrl(`${this.apiUrl}/initialize/getdata`, {});
    return res;
  }

  getSilphBosses = async (): Promise<Alias | { error: string; }> => {
    const res = await this.__getUrl(`${this.apiUrl}/silphbosses`);
    return res;
  }

  addPokemon = async (data: {}): Promise<{ success: boolean, pokemon: Pokemon } | { error: string }> => {
    const res = await this.__postUrl(`${this.apiUrl}/pokemon/addpokemon`, data);
    return res;
  }

  updateMultiple = async (data: {}): Promise<{ success: boolean; pokemon: Pokemon[]; } | { error: string; }> => {
    const res = await this.__postUrl(`${this.apiUrl}/pokemon/updatemultiple`, data);
    return res;
  }
}
export default new Api();