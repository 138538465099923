import { ChangeEvent, FC, useState } from "react"
import { Preloader } from "react-materialize";
import Api from "../../../api/api";
import Header from "../../Header"

interface Pokemon {
  number: string;
  atk: number;
  def: number;
  sta: number;
  name: string;
  type: string;
  raidlevel: number;
}

const AdminAddPokemonPage: FC = () => {

  const [number, setNumber] = useState<string>('');
  const [atk, setAtk] = useState<string>('');
  const [def, setDef] = useState<string>('');
  const [sta, setSta] = useState<string>('');
  const [name, setName] = useState<string>('');
  const [type, setType] = useState<string>('');
  const [loading, setLoading] = useState<boolean>(false);

  const submitPokemon = async () => {
    if (name === '' || atk === '' || def === '' || sta === '' || number === '' || type === '') {
      console.log('Invalid');
      return;
    }

    setLoading(true);

    const pokemon: Pokemon = {
      name: name,
      atk: +atk,
      def: +def,
      sta: +sta,
      number: number,
      type: type,
      raidlevel: -1
    }

    const result  = await Api.addPokemon({pokemon});
    console.log(result);
    setName('');
    setNumber('');
    setType('');
    setAtk('');
    setDef('');
    setSta('');
    setLoading(false)
  }

  return (
    <>
      <Header page="addpokemon" backbutton={true} title="Pokemon Toevoegen" />
      <div className="content-container">
        {loading ?
          <div className="loader">
            <Preloader
              active
              color="blue"
              flashing={false}
              size="big"
            />
            <p>Pokemon toevoegen...</p>
          </div>
          : <>
            <div className="margin-bottom-10">Vul onderstaande velden in om een nieuwe pokemon toe te voegen.</div>
            <div className="divider margin-bottom-30"></div>
            <div className="">Naam:</div>
            <div className="input-field">
              <input type="text" value={name} onChange={(ev: ChangeEvent<HTMLInputElement>) => setName(ev.target.value)} />
            </div>
            <div className="">Nummer:</div>
            <div className="input-field">
              <input type="text" value={number} onChange={(ev: ChangeEvent<HTMLInputElement>) => setNumber(ev.target.value)} />
            </div>
            <div className="">Type:</div>
            <div className="input-field">
              <input type="text" value={type} onChange={(ev: ChangeEvent<HTMLInputElement>) => setType(ev.target.value)} />
            </div>
            <div className="">Attack:</div>
            <div className="input-field">
              <input type="number" value={atk} onChange={(ev: ChangeEvent<HTMLInputElement>) => setAtk(ev.target.value)} />
            </div>
            <div className="">Defense:</div>
            <div className="input-field">
              <input type="number" value={def} onChange={(ev: ChangeEvent<HTMLInputElement>) => setDef(ev.target.value)} />
            </div>
            <div className="">Stamina:</div>
            <div className="input-field">
              <input type="number" value={sta} onChange={(ev: ChangeEvent<HTMLInputElement>) => setSta(ev.target.value)} />
            </div>
            <button className="btn" onClick={() => submitPokemon()}>Toevoegen</button>
          </>
        }
      </div>
    </>
  )
}

export default AdminAddPokemonPage
