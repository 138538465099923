import { FC } from 'react';
import Share from '@material-ui/icons/Share';
import { Poi } from '../../Interfaces';

//document.getElementById('error-text').innerText = 'Er is van deze gym nog geen exacte locatie bekend.';

interface Props {
  gym: Poi;
  showError: () => void;
}

const FindGymItem: FC<Props> = ({gym, showError}) => {
  
  const openGymInMaps = (gym: Poi) => {
    const coords: string = gym.coords.replace(/ /g, '');
    if (!coords.includes(',')) {
      showError();
      return;
    }
    window.open(`https://www.google.com/maps/search/?api=1&query=${coords}`);
  }

  const shareGym = (gym: Poi) => {
    const coords: string = gym.coords.replace(/ /g, '');
    if (!coords.includes(',')) {
      showError();
      return;
    }
    const message: string = encodeURIComponent(`${gym.name}\n\nhttps://www.google.com/maps/search/?api=1&query=${coords}`);
    window.open(`whatsapp://send?text=${message}`);
  }

  return (
    <div className="rounded-box">
      <div className="row valign-wrapper no-margin-bottom">
        <div onClick={() => openGymInMaps(gym)} className="col s10">
          <div className="title-small truncate"><span>{gym.name}</span></div>
          <div className="subtitle"><span>{gym.location}</span></div>
        </div>
        <div className="col s2 right-align"><Share onClick={() => shareGym(gym)} /></div>
      </div>
    </div>
  );
}

export default FindGymItem;