import { FC, useContext, useEffect, useRef } from 'react';
import { Preloader } from 'react-materialize';
import Api from '../../api/api';
import logo from '../../images/logo.png';
import { Poi, Pokemon } from '../../Interfaces';
import DataContext from '../../store/data-context';

const LoadingPage: FC = () => {
  const dataContext = useRef(useContext(DataContext));

  const openDB = async (): Promise<{ db: IDBDatabase, upgraded: boolean } | null> => {
    return await new Promise((resolve, reject) => {
      const DBOpenReq = indexedDB.open('raids0251', 1);
      let upgraded = false;
      DBOpenReq.onupgradeneeded = (ev: any) => {
        const db: IDBDatabase = ev.target.result;
        if (db.objectStoreNames.contains('pokemon')) { db.deleteObjectStore('pokemon'); }
        if (db.objectStoreNames.contains('pois')) { db.deleteObjectStore('pois'); }
        if (db.objectStoreNames.contains('settings')) { db.deleteObjectStore('settings'); }
        db.createObjectStore('pokemon', { keyPath: '_id' });
        db.createObjectStore('pois', { keyPath: '_id' });
        db.createObjectStore('settings', { keyPath: 'name' });
        upgraded = true;
      }
      DBOpenReq.onsuccess = (ev: any) => {
        resolve({ db: ev.target.result, upgraded });
      }
      DBOpenReq.onerror = () => {
        resolve(null);
      }
    });
  }

  const getLastUpdateFromDB = async (db: IDBDatabase, store: string, key: string): Promise<number> => {
    return await new Promise((resolve, reject) => {
      try {
        const request: IDBRequest = db.transaction([store]).objectStore(store).get(key);
        request.onsuccess = (ev: any) => {
          resolve(ev.target.result?.value || 0);
        }
      } catch {
        resolve(0);
      }
    });
  }

  const getAllFromDB = async (db: IDBDatabase, store: string): Promise<any> => {
    return await new Promise((resolve, reject) => {
      try {
        const request = db.transaction([store]).objectStore(store).getAll();
        request.onsuccess = (ev: any) => {
          resolve(ev.target.result || undefined);
        }
      } catch (e) {
        resolve([]);
      }
    });
  }

  const getDataFromIndexedDb = useRef(async (db: IDBDatabase): Promise<{ pokemon: Pokemon[], pois: Poi[], settings: { admin: number; raidtexttemplate: string; raidtimer: number; updated: number; } }> => {
    
    const pokemon: Pokemon[] = await getAllFromDB(db, 'pokemon');
    const pois: Poi[] = await getAllFromDB(db, 'pois');
    const _settings: { name: string, value: any }[] = await getAllFromDB(db, 'settings');
    const settings: { admin: number; raidtexttemplate: string; raidtimer: number; updated: number; } = {
      admin: _settings.find(setting => setting.name === 'admin')?.value || 0,
      raidtexttemplate: _settings.find(setting => setting.name === 'raidtexttemplate')?.value || '',
      raidtimer: _settings.find(setting => setting.name === 'raidtimer')?.value || 45,
      updated: _settings.find(setting => setting.name === 'updated')?.value || 0
    };
    return { pokemon, pois, settings }
  });

  const getDataFromApi = async (): Promise<{ pokemon: Pokemon[], pois: Poi[], settings: { admin: number; raidtexttemplate: string; raidtimer: number; updated: number; } }> => {
    const data = await Api.getDbData();
    if (!('error' in data)) {
      let { settings, pokemon, pois } = data;
      return { pokemon, pois, settings };
    }
    return { pokemon: [], pois: [], settings: { admin: 0, raidtexttemplate: '', raidtimer: 45, updated: 0 } };
  };

  const storeInIndexedDb = async (db: IDBDatabase, pokemon: Pokemon[], pois: Poi[], settings: { [key: string]: any; }): Promise<void> => {
    return await new Promise((resolve, reject) => {
      const tx = db.transaction(['pois', 'pokemon', 'settings'], 'readwrite');
      const poiStore = tx.objectStore("pois");
      const pokemonStore = tx.objectStore('pokemon');
      const settingsStore = tx.objectStore('settings');
      poiStore.clear();
      pokemonStore.clear();
      settingsStore.clear();
      pois.forEach(poi => poiStore.add(poi));
      pokemon.forEach(pokemon => pokemonStore.add(pokemon));
      Object.keys(settings).forEach(key => settingsStore.add({ 'name': key, 'value': settings[key] }));
      tx.oncomplete = () => {
        resolve();
      };
      tx.onerror = () => {
        resolve();
      };
    });
  }

  useEffect(() => {
    (async function initData() {
      let dbobject = await openDB();
      let _pokemon: Pokemon[] = [];
      let _pois: Poi[] = [];
      let _settings: { admin?: number; raidtexttemplate?: string; raidtimer?: number; updated?: number; initstatus?: string } = {};

      if (dbobject !== null) {
        try {
          const apidata = await Api.init();
          if (!('error' in apidata)) {
            const lastIndexeddbUpdate = await getLastUpdateFromDB(dbobject.db, 'settings', 'updated') || 0;
            if (apidata.updated > lastIndexeddbUpdate || localStorage.getItem('forcedupdate') !== null) {
              const data = await getDataFromApi();
              await storeInIndexedDb(dbobject.db, data.pokemon, data.pois, data.settings);
              _pokemon = data.pokemon;
              _pois = data.pois;
              _settings = data.settings;
              localStorage.removeItem('forcedupdate');
            } else {
              const data = await getDataFromIndexedDb.current(dbobject.db)
              _pokemon = data.pokemon;
              _pois = data.pois;
              _settings = data.settings;
            }
          } else {
            const data = await getDataFromIndexedDb.current(dbobject.db)
            _pokemon = data.pokemon;
            _pois = data.pois;
            _settings = data.settings;
          }
        } catch {
          const data = await getDataFromIndexedDb.current(dbobject.db)
          _pokemon = data.pokemon;
          _pois = data.pois;
          _settings = data.settings;
        }
      } else {
        const data = await getDataFromApi();
        _pokemon = data.pokemon;
        _pois = data.pois;
        _settings = data.settings;
      }

      if (_pokemon.length > 0 && _pois.length > 0 && Object.keys(_settings).length > 0) {
        _settings.initstatus = 'initialized';
        dataContext.current.storePokemon(_pokemon);
        dataContext.current.storePois(_pois.sort((a: Poi, b: Poi) => a.name.localeCompare(b.name)));
        dataContext.current.storeSettings({ ..._settings });
      } else {
        dataContext.current.storeSettings({ initstatus: 'error' });
      }
    })();
  }, []);

  return (
    <div className="loader">
      <img src={logo} alt="" />
      <Preloader
        active
        color="blue"
        flashing={false}
        size="big"
      />
      <p>Bezig met laden...</p>
    </div>
  );
}

export default LoadingPage;