import { FC } from "react";
import Header from "../../Header";
import HomeMenuItem from "../../Home/HomeMenuItem";
import Add from '@material-ui/icons/Add';
import Search from '@material-ui/icons/Search';

const AdminHomepage: FC = () => {
  return (
    <>
      <Header backbutton={true} page="adminhome" title="Administratie" />
      <div className="content-container">
        <div className="menu-sub">Gyms</div>
        <HomeMenuItem icon={<Add />} text="Nieuwe gym toevoegen" location="/findgym" />
        <div className="menu-sub">Raids</div>
        <HomeMenuItem icon={<Search />} text="Raidbosses beheren" location="/admin/manageraidbosses" />
        <HomeMenuItem icon={<Search />} text="Controleer raidbosses" location="/admin/checkraidbosses" />
        <div className="menu-sub">Pokemon</div>
        <HomeMenuItem icon={<Add />} text="Pokemon toevoegen" location="/admin/addpokemon" />
      </div>
    </>
  )
}

export default AdminHomepage;