import { FC } from 'react';
import { Pokemon } from '../../Interfaces';

interface Props {
  raidboss: Pokemon;
}

const RaidbossItem: FC<Props> = ({ raidboss }) => {
  return (
    <div className="rounded-box">
      <div className="title margin-bottom-10">{raidboss.name}</div>
      <div>CP Range op level 20: <span className="margin-left-10">{raidboss.mincp20} - {raidboss.maxcp20}</span></div>
      <div className="margin-bottom-10">CP Range op level 25: <span className="margin-left-10">{raidboss.mincp25} - {raidboss.maxcp25}</span></div>
      <div>Type: {raidboss.type}</div>
      <div className="margin-bottom-10">Weatherboosted: {raidboss.weatherboosted}</div>
      {raidboss.doubleweakness !== "" && <div>Double weakness: {raidboss.doubleweakness}</div>}
      {raidboss.weakness !== "" && <div>Weakness: {raidboss.weakness}</div>}
    </div>
  );
}

export default RaidbossItem;