import { ChangeEvent, FC, useContext, useEffect, useRef, useState } from "react";
import DataContext from "../../store/data-context";
import M from "materialize-css";
import moment from 'moment';
import { Poi, Pokemon, Raidtext } from "../../Interfaces";
import { Select, Switch, TimePicker } from "react-materialize";

interface Props {
  raidtextid: number
  single: boolean
  onsuccess: (raidtext: Raidtext) => void;
}

const RaidForm: FC<Props> = ({raidtextid, single, onsuccess}) => {
  const dataContext = useContext(DataContext);
  const [raidText, setRaidtext] = useState<Raidtext>(dataContext.getRaidtext(raidtextid));
  const [modalHeader, setModalHeader] = useState('');
  const [modalText, setModalText] = useState('');
  const raidbosses: Pokemon[] = dataContext.getRaidbosses();
  const pois: Poi[] = dataContext.pois;
  const raidDuration: number = dataContext.settings.raidtimer;
  const modalButtonRef = useRef<HTMLButtonElement>(null);
  const previousRaidGym = dataContext.getLastRaidGym(raidtextid);

  useEffect(() => {
    let elems = document.querySelectorAll('.modal');
    M.Modal.init(elems, {
      dismissible: false
    });
  }, []);

  useEffect(() => {
    const eggopenElement = document.getElementById('form-eggopen');
    if (eggopenElement) {
      (eggopenElement as HTMLInputElement).placeholder = 'Selecteer tijd';
      (eggopenElement as HTMLInputElement).readOnly = true;
      (eggopenElement as HTMLInputElement).value = raidText.eiopen;
    }
  }, [raidText.eiopen]);

  useEffect(() => {
    const raidstartElement = document.getElementById('form-start');
    if (raidstartElement) {
      (raidstartElement as HTMLInputElement).placeholder = 'Selecteer tijd';
      (raidstartElement as HTMLInputElement).readOnly = true;
      (raidstartElement as HTMLInputElement).value = raidText.start;
    }
  }, [raidText.start]);

  const openModal = () => {
    modalButtonRef.current?.click();
  }

  const onGymChange = (ev: ChangeEvent<HTMLSelectElement>) => {
    const gym = pois.find(poi => poi._id === ev.target.value);
    if (gym) {
      setRaidtext(prev => {
        return { ...prev, gym: gym }
      });
    }
  }

  const onRaidbossChange = (ev: ChangeEvent<HTMLSelectElement>) => {
    setRaidtext(prev => {
      return { ...prev, raidboss: ev.target.value }
    });
  }

  const toggleAddMyself = () => {
    if (!raidText.addmyself && (localStorage.getItem('whatsappname') || '') === '') {
      setModalHeader('Fout opgetreden');
      setModalText('Om deze optie te gebruiken dien je eerst een naam en level op te geven bij Instellingen.');
      openModal();
      return;
    }

    setRaidtext(prev => {
      return { ...prev, addmyself: !prev.addmyself }
    });
  }

  const toggleStartAfterPrevious = () => {
    setRaidtext(prev => {
      return { ...prev, startafterprevious: !prev.startafterprevious }
    });
  }

  const eiOpenTimeSet = () => {
    const eggopenElement = document.getElementById('form-eggopen');
    if (eggopenElement) {
      const eggOpen = (eggopenElement as HTMLInputElement).value;
      if (eggOpen.split(':').length === 2) {
        const endTime = moment(eggOpen, 'HH:mm').add(raidDuration, 'minutes').format('HH:mm');
        (eggopenElement as HTMLInputElement).value = `${eggOpen} - ${endTime}`;
      }
      setRaidtext(prev => {
        return { ...prev, eiopen: (eggopenElement as HTMLInputElement).value }
      })
    }
  }

  const raidStartSet = () => {
    const raidstartElement = document.getElementById('form-start');
    if (raidstartElement) {
      const start = (raidstartElement as HTMLInputElement).value;
      setRaidtext(prev => {
        return { ...prev, start: start }
      })
    }
  }

  const onSubmit = () => {
    if (!raidText.eiopen.includes('-')) {
      setModalHeader('Fout opgetreden');
      setModalText('Kies een geldige tijd bij \'Raid Open\'.');
      openModal();
      return;
    }

    if (!raidText.startafterprevious && raidText.start.includes(':')) {
      const raidTimeArray = raidText.eiopen.split(' - ');
      const eggOpenTime = moment(raidTimeArray[0], 'HH:mm');
      const eggEndTime = moment(raidTimeArray[1], 'HH:mm');
      const starttime = moment(raidText.start, 'HH:mm');
      if (!starttime.isBetween(eggOpenTime, eggEndTime, 'minutes', '[]')) {
        setModalHeader('Fout opgetreden');
        setModalText('Starttijd valt buiten de raid tijd.');
        openModal();
        return;
      }
    }

    onsuccess(raidText);
  }

  return (
    <>
      <div className="content-container">
        <div className="margin-top-10">Selecteer Raidboss:</div>
        <Select id="form-raidboss" multiple={false} onChange={onRaidbossChange} value={raidText.raidboss}
          options={{
            dropdownOptions: { alignment: 'left', closeOnClick: true, constrainWidth: false, coverTrigger: true, hover: false, inDuration: 150, outDuration: 250 }
          }}
        >
          <option value="Mega Raid">=== Mega Raid ===</option>
          {raidbosses.filter(raidboss => raidboss.raidlevel === 6).map((boss) => (<option key={boss._id} value={boss.name}>{boss.name}</option>))}
          <option disabled value=""></option>
          <option value="5 sterren">=== 5 Sterren ===</option>
          {raidbosses.filter(raidboss => raidboss.raidlevel === 5).map((boss) => (<option key={boss._id} value={boss.name}>{boss.name}</option>))}
          <option disabled value=""></option>
          <option value="3 sterren">=== 3 Sterren ===</option>
          {raidbosses.filter(raidboss => raidboss.raidlevel === 3).map((boss) => (<option key={boss._id} value={boss.name}>{boss.name}</option>))}
          <option disabled value=""></option>
          <option value="1 ster">=== 1 Ster ===</option>
          {raidbosses.filter(raidboss => raidboss.raidlevel === 1).map((boss) => (<option key={boss._id} value={boss.name}>{boss.name}</option>))}
        </Select>
        <div style={{ justifyContent: 'space-between' }} className="horizontal-box">
          <div className="">Selecteer Gym:</div>
          {raidText.gym.type === 2 && <div id="exraidicon" className="exraidicon center-align">EXRAID</div>}
        </div>
        <Select id="form-gym" multiple={false} onChange={onGymChange} value={raidText.gym._id}
          options={{
            dropdownOptions: { alignment: 'left', closeOnClick: true, constrainWidth: false, coverTrigger: true, hover: false, inDuration: 150, outDuration: 250 }
          }}
        >
          {pois.map((poi) => (<option key={poi._id} value={poi._id}>{poi.name}</option>))}
        </Select>
        <div>Locatie:</div>
        <div className="input-field">
          <input type="text" value={raidText.gym.location} readOnly />
        </div>
        <div>Raid Open:</div>
        <TimePicker id="form-eggopen"
          options={{
            onCloseEnd: eiOpenTimeSet, autoClose: true, i18n: { cancel: '', clear: 'Wissen', done: 'Ok' }, showClearBtn: true, twelveHour: false, vibrate: true
          }}
        />
        {!raidText.startafterprevious && <div>Starttijd: (leeg laten indien onbekend)</div>}
        {!raidText.startafterprevious && <TimePicker id="form-start"
          options={{
            onCloseEnd: raidStartSet, autoClose: true, i18n: { cancel: '', clear: 'Wissen', done: 'Ok' }, showClearBtn: true, twelveHour: false, vibrate: true
          }}
        />}
        {('name' in previousRaidGym && !single) &&
          <div style={{ justifyContent: 'space-between' }} className="horizontal-box margin-bottom-20">
            <div>Start na {previousRaidGym.name}</div>
            <Switch onChange={toggleStartAfterPrevious} checked={raidText.startafterprevious} onLabel='' offLabel='' />
          </div>
        }
        <div style={{ justifyContent: 'space-between' }} className="horizontal-box">
          <div>Voeg mijzelf toe aan deze raid</div>
          <Switch onChange={toggleAddMyself} checked={raidText.addmyself} onLabel='' offLabel='' />
        </div>
      </div>
      <div className="footer-container">
        <button onClick={onSubmit} id='form-createtext' className={single ? "btn blue" : "btn"}>{single ? 'Delen in WhatsApp' : raidtextid > -1 ? 'Opslaan' : 'Toevoegen'}</button>
      </div>
      <button ref={modalButtonRef} data-target="message-modal" className="btn modal-trigger hide" id="modal-button">Modal</button>
      <div id="message-modal" className="modal bottom-sheet">
        <div className="modal-content black-text">
          <h5>{modalHeader}</h5>
          <div className="divider"></div>
          <p>{modalText}</p>
        </div>
        <div className="container center-align margin-bottom-20">
          <button className="modal-action modal-close btn">Sluiten</button>
        </div>
      </div>
    </>
  )
}

export default RaidForm;