import { FC } from "react";
import { Raidtext } from "../../Interfaces";
import Header from "../Header";
import RaidForm from "./RaidForm";

interface Props {
  onsuccess: (raidtext: Raidtext) => void;
  raidtextid: number;
  single: boolean;
}

const CreateRaid: FC<Props> = ({onsuccess, raidtextid, single}) => {
  return (
    <>
      <Header page="raidform" backbutton={true} title="Raidtekst Maken" />
      <RaidForm onsuccess={onsuccess} raidtextid={raidtextid} single={single} />
    </>
  )
}

export default CreateRaid;