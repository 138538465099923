import { FC, useContext } from 'react';
import ArrowBack from '@material-ui/icons/ArrowBack';
import logo from '../images/logo.png';
import Settings from '@material-ui/icons/Settings';
import Security from '@material-ui/icons/Security';
import DataContext from '../store/data-context';
import Refresh from '@material-ui/icons/Refresh';
import { useHistory } from 'react-router';

interface Props {
  backbutton?: boolean,
  page: string,
  title: string
  clearraidtexts?: () => void
}

const Header: FC<Props> =  ({backbutton, page, title, clearraidtexts}) => {
  const dataContext = useContext(DataContext);
  const history = useHistory();

  const backButtonPressed = () => {
    if (history.location.state) {
      history.goBack();
    } else {
      if (history.location.pathname.startsWith('/admin/')) {
        history.replace('/admin');
      } else {
        history.replace('/');
      }
    }
  }

  const adminButtonPressed = () => {
    history.push('/admin', { from: 'home' });
  }

  const openSettings = () => {
    history.push('/settings', { from: 'home' });
  }

  return (
    <div className="top-container">
      <div className="top-header">
        {backbutton && <span className="left" onClick={() => backButtonPressed()}>
          <ArrowBack style={{ fontSize: "25px", verticalAlign: "middle" }} />
        </span>}
        {page === 'home' && <img className="top-logo left" src={logo} alt='' />}
        <div className="left title-bar">{title}</div>
        {page === 'home' && <span className="right" onClick={openSettings}><Settings style={{ fontSize: "25px", verticalAlign: "middle" }} /></span>}
        {(dataContext.settings.admin > 0 && page === 'home') && <span className="right" onClick={adminButtonPressed}><Security style={{ marginRight: "15px", fontSize: "25px", verticalAlign: "middle" }} /></span>}
        {page === 'createmultiraid' && <span className="right" onClick={clearraidtexts}><Refresh style={{ fontSize: "25px", verticalAlign: "middle" }} /></span>}
      </div>
    </div>
  );
}

export default Header;