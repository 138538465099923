import { ChangeEvent, FC, useContext, useEffect, useRef, useState } from 'react';
import { Button, Select, Switch } from 'react-materialize';
import M from "materialize-css";
import Header from '../Header';
import DataContext from '../../store/data-context';

const SettingsPage: FC = () => {
  const dataContext = useContext(DataContext);
  const [whatsappName, setWhatsAppName] = useState<string>(localStorage.getItem('whatsappname') || '');
  const [currentLevel, setCurrentLevel] = useState<string>(localStorage.getItem('currentlevel') || '1');
  const [autoAdd, setAutoAdd] = useState<boolean>(localStorage.getItem('autoadd') === 'ja' || false);
  const [modalHeader, setModalHeader] = useState<string>('');
  const [modalText, setModalText] = useState<string>('');
  const modalButtonRef = useRef<HTMLButtonElement>(null);

  useEffect(() => {
    const elems = document.querySelectorAll('.modal');
    M.Modal.init(elems, {
      dismissible: false
    });
  }, []);

  const changeLevel = (e: ChangeEvent<HTMLSelectElement>): void => {
    setCurrentLevel(e.target.value);
  }

  const changeName = (e: ChangeEvent<HTMLInputElement>): void => {
    setWhatsAppName(e.target.value);
    if (e.target.value === '') {
      setAutoAdd(false);
    }
  }

  const toggleAutoAdd = (): void => {
    if (!autoAdd && whatsappName === '') {
      setModalHeader('Fout opgetreden');
      setModalText('Vul een naam in alvorens deze optie in te schakelen.');
      showModal();
      return;
    }
    setAutoAdd(prevState => !prevState);
  }

  const saveSettings = (): void => {
    if (whatsappName.length > 2 && whatsappName.startsWith('::')) {
      localStorage.setItem('auth-token', whatsappName.slice(2));
      localStorage.setItem('forcedupdate', '1');
      setWhatsAppName(localStorage.getItem('whatsappname') || '');
      setModalHeader('Success');
      setModalText('API-token is opgeslagen. Herstart de app om deze toe te passen.');
      showModal();
      return;
    }
    localStorage.setItem('whatsappname', whatsappName);
    localStorage.setItem('currentlevel', currentLevel);
    localStorage.setItem('autoadd', autoAdd ? 'ja' : 'nee');
    setModalHeader('Success');
    setModalText('Je instellingen zijn opgeslagen.');
    showModal();
  }

  const showModal = (): void => {
    modalButtonRef.current?.click();
  }

  return (
    <>
      <Header page="settings" backbutton={true} title="Instellingen" />
      <div className="content-container">
        <div>Hier kun je de instellingen wijzigen die gebruikt kunnen worden bij verschillende onderdelen van de app.</div>
        <div className="margin-bottom-20">Deze instellingen zullen alleen op dit apparaat opgeslagen worden.</div>
        <div className="divider margin-bottom-30"></div>
        <div>Welke naam gebruik je op WhatsApp voor de raids?</div>
        <input className="margin-bottom-30" placeholder="Vul hier je naam in..." id="form-name" type="text" onChange={changeName} value={whatsappName} />
        <div>Wat is je huidige level?</div>
        <Select multiple={false} onChange={changeLevel}
          options={{
            dropdownOptions: { alignment: 'left', autoTrigger: true, closeOnClick: true, constrainWidth: false, coverTrigger: true, hover: false, inDuration: 150, outDuration: 250 }
          }}
          value={currentLevel}
        >
          {new Array(50).fill(1).map((_, i) => <option key={`level${i + 1}`} value={`${i + 1}`}>{i + 1}</option>)}
        </Select>
        <div style={{ justifyContent: 'space-between' }} className="horizontal-box margin-top-10 margin-bottom-20">
          <div>Voeg mij automatisch toe aan raids die ik meld</div>
          <Switch
            id="Switch-20"
            onChange={toggleAutoAdd}
            checked={autoAdd}
            onLabel=''
            offLabel=''
          />
        </div>
        <Button onClick={saveSettings}>Opslaan</Button>
      </div>
      <div className="flex-container right-align margin-bottom-10">Ver. {dataContext.settings.version}</div>
      <button ref={modalButtonRef} data-target="message-modal" className="btn modal-trigger hide" id="modal-button">Modal</button>
      <div id="message-modal" className="modal bottom-sheet">
        <div className="modal-content black-text">
          <h5>{modalHeader}</h5>
          <div className="divider"></div>
          <p>{modalText}</p>
        </div>
        <div className="container center-align margin-bottom-20">
          <button className="modal-action modal-close btn">Sluiten</button>
        </div>
      </div>
    </>
  );
}

export default SettingsPage;