import { useEffect, useRef, useState, useContext, FC, ChangeEventHandler } from 'react';
import Header from '../Header';
import Close from '@material-ui/icons/Close';
import M from "materialize-css";
import DataContext from '../../store/data-context';
import FindGymItem from './FindGymItem';
import { Poi } from '../../Interfaces';

const FindGymPage: FC = () => {
  const dataContext = useContext(DataContext);
  const gyms: Poi[] = dataContext.pois;
  const [foundGyms, setFoundGyms] = useState<Poi[]>(gyms);
  const searchRef = useRef<HTMLInputElement>(null);
  const modalButtonRef = useRef<HTMLButtonElement>(null);

  const clearSearch = () => {
    searchRef.current!.value = '';
    setFoundGyms(gyms);
  }

  const updateSearch: ChangeEventHandler<HTMLInputElement> = (ev) => {
    setFoundGyms(gyms.filter(gym => gym.name.match(new RegExp(ev.target.value, 'gi'))));
  }

  useEffect(() => {
    const elems = document.querySelectorAll('.modal');
    M.Modal.init(elems, {
      dismissible: false
    });
  })

  const showError = () => {
    modalButtonRef.current?.click();
  }

  return (
    <>
      <Header page="findgym" backbutton={true} title="Gym Zoeken" />
      <div className="flex-container">
        <div className="margin-top-10">
          <div className="margin-bottom-10">Druk op de gym die je zoekt om de locatie ervan te openen in Google Maps, of druk op het icoontje aan de rechterkant om deze locatie te delen in WhatsApp.</div>
          <div className="rounded-box">
            <div className="input-field no-margin-bottom">
              <Close onClick={() => clearSearch()} className="suffix right" />
              <input ref={searchRef} onChange={updateSearch} id="gym-search" className="input-small" type="text" placeholder="Zoek een gym" />
            </div>
          </div>
          <div className="divider"></div>
        </div>
      </div>
      <div className="content-container">
        <div className="gyms" id="gyms">
          {foundGyms.map(gym => <FindGymItem showError={showError} key={gym._id} gym={gym} />)}
          {foundGyms.length === 0 && <div>Er zijn geen gyms gevonden met de huidige zoekterm.</div>}
        </div>
      </div>
      <button ref={modalButtonRef} data-target="error-modal" className="btn modal-trigger hide" id="error-button">Modal</button>
      <div id="error-modal" className="modal bottom-sheet">
        <div className="modal-content black-text">
          <h5>Fout opgetreden</h5>
          <div className="divider"></div>
          <p id="error-text">Er is van deze gym nog geen exacte locatie bekend.</p>
        </div>
        <div className="container center-align margin-bottom-20">
          <button className="modal-action modal-close btn">Sluiten</button>
        </div>
      </div>
    </>
  );
}

export default FindGymPage;